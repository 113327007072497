.hex-code-section {
  margin-top: 24px;
  margin-bottom: 24px;
}

.searched-color {
  margin-left: 8px;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  height: 38px;
  width: 38px;
}

.hex-code-label::before {
  content: "*";
  color: #EA001E;
}
