.user-context-section {
  margin-top: 24px;
  margin-bottom: 24px;
}

.user-context-form-select {
  margin-left: 8px;
  width: 180px;
  height: 30px;
}

.user-context-form-label::before {
  content: "*";
  color: #EA001E;
}