.styling-hooks-search-results {
  padding-top: 18px;
}

.search-results-heading {
  white-space: pre-wrap;
}

.table {
  /* border: 1px solid #CCC; */
}

.table td {
  vertical-align: middle;
  height: 75px;
}
