.rgb-section {
  margin-top: 24px;
  margin-bottom: 24px;
}

.searched-color {
  margin-left: 8px;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  height: 38px;
  width: 38px;
}

.rgb-to-hexCode-section {
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-weight: bold;
}

.rgb-label::before {
  content: "*";
  color: #EA001E;
}