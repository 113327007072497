.results-disclaimer {
  display: flex;
}

.disclaimer {
  color: #EA001E;
  margin-right: 4px;
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}